<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        {{ invitation.id }}
      </VToolbarTitle>

      <VSpacer />

      <VTooltip
        v-if="$route.name !== Names.R_INVITATION"
        left
      >
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            exact
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_INVITATION, params : { invitationId : $route.params.invitationId } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            dark
            exact
            color="orange"
            class="mx-1"
            :to="{ name : Names.R_INVITATION_EDIT, params : { invitationId : invitation.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>
    </VToolbar>

    <VDivider />

    <VCardText>
      <VExpansionPanels
        accordion
        flat
      >
        <VExpansionPanel>
          <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
          <VExpansionPanelContent>
            <code class="elevation-0 d-block">{{ invitation }}</code>
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_INVITATION } from './common';

export default {
  name: 'InvitationCard',

  inject: ['Names'],

  props: {
    companies: {
      type: Array,
      required: true,
    },

    invitation: {
      type: Object,
      default: () => ({ ...DEFAULT_INVITATION }),
    },
  },
};
</script>
