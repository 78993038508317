<template>
  <TTView>
    <VRow>
      <VCol>
        <InvitationCard
          :companies="companies"
          :invitation="invitation"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_INVITATION } from '../../components/invitations/common';
import InvitationCard from '../../components/invitations/InvitationCard.vue';

export default {
  name: 'Invitation',

  components: {
    InvitationCard,
  },

  data() {
    return {
      companies: [],
      invitation: { ...DEFAULT_INVITATION },
      loading: false,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { invitationId: id } = this.$route.params;
        const data = { id };
        const [companiesResponse, invitationResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.Invitations.InvitationGet(data),
        ]);

        this.companies = companiesResponse.companies || [];
        this.invitation = invitationResponse.invitation || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
